var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.newDetail && _vm.webDetail)?_c('div',{staticClass:"detail-header"},[_c('div',{staticClass:"top-box"},[(_vm.newDetail.top_video)?_c('div',{ref:"videoWrapper",staticClass:"banner"},[(_vm.isPlay)?_c('DPlayerVideo',{ref:"dplayerVideo",attrs:{"options":_vm.topVideoOpts},on:{"ended":_vm.videoEnded}}):_c('img',{attrs:{"width":"100%","height":"382px","src":_vm.newDetail.top_video.poster}})],1):_vm._e(),_c('div',{staticClass:"gmae-info"},[_c('div',{staticClass:"gmae-icon__box"},[_c('HaloImg',{staticClass:"game-icon",attrs:{"src":_vm.webDetail.icon}}),(_vm.ranking)?_c('div',{staticClass:"game-icon__ranking"},[_c('div',{staticClass:"game-icon__bg",style:({
              backgroundImage: ("url(" + (require("@/assets/images/bg_bangdan.png")) + ")")
            })},[_c('div',{staticClass:"flex game-icon__info"},[_c('img',{staticStyle:{"width":"16px","height":"16px"},attrs:{"src":require("@/assets/images/icon_bangdan.png"),"alt":"BNAGDAN"}}),_c('p',{staticClass:"game-icon__info-desc"},[_c('span',[_vm._v("预约榜第"+_vm._s(_vm.ranking.no)+"名")])]),_c('img',{staticStyle:{"width":"14px","height":"14px"},attrs:{"src":require("@/assets/images/bangdan_icon_more.png"),"alt":"BNAGDAN_MORE"}})])])]):_vm._e()],1),_c('div',{staticClass:"info"},[_c('div',[_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.webDetail.name)+" ")]),(_vm.newDetail.new_tag_style)?_c('div',{staticClass:"flex tags"},[_vm._l((_vm.newDetail.new_tag_style.slice(0, 5)),function(item,index){return _c('router-link',{key:index,staticClass:"tag",attrs:{"to":'/tag/' + item._seq}},[_vm._v(" "+_vm._s(item.name)+" ")])}),(_vm.newDetail.new_tag_style.length > 5)?_c('div',{staticClass:"more-tag-btn",on:{"click":_vm.handleMoreTags}},[_c('img',{attrs:{"width":"100%","height":"100%","src":require("@/assets/images/icon_tag_more.png"),"alt":"more"}})]):_vm._e()],2):_vm._e()]),_c('div',{staticClass:"flex"})]),_c('div',{staticClass:"evaluation flex"},[_c('HaloImg',{staticClass:"game-level flex",attrs:{"src":require("@/assets/images/icon_pingfen.png"),"alt":"评分"}},[_c('div',{staticClass:"star"},[_vm._v(_vm._s(_vm.webDetail.new_star))])])],1)]),(_vm.newDetail.event)?_c('div',{staticClass:"up-service",style:({
        backgroundColor: _vm.newDetail.event.high_light
          ? "rgba(36, 149, 255, 0.1)"
          : "rgba(244, 245, 246, 0.6)",
        color: _vm.newDetail.event.high_light ? "#2496ff" : "#999"
      }),on:{"click":function($event){return _vm.handleDownloadGame(_vm.downloadDialogType.GAME_DETAIL)}}},[_c('img',{attrs:{"src":require(("@/assets/images/icon_rili" + (_vm.newDetail.event.high_light ? '_blue' : '') + ".png")),"width":"16px","alt":"DATE"}}),_c('p',{staticClass:"up-service__desc"},[_vm._v(" "+_vm._s(_vm.TimestampFormat(_vm.newDetail.event.time))+"："+_vm._s(_vm.newDetail.event.content)+" ")]),_c('img',{staticClass:"up-service__icon",attrs:{"src":require(("@/assets/images/icon_right_arrow" + (_vm.newDetail.event.high_light ? '_blue' : '') + ".png")),"alt":"ARROW"}})]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeaderFixed),expression:"showHeaderFixed"}],staticClass:"header-fixed"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"back",on:{"click":_vm.handleBack}},[_c('img',{attrs:{"src":require("@/assets/images/icon_return.png"),"width":"40","height":"40"}})]),_c('div',{staticClass:"game-info flex"},[_c('div',{staticClass:"gmae-icon__box"},[_c('HaloImg',{staticClass:"game-icon",attrs:{"src":_vm.webDetail.icon}}),(_vm.ranking)?_c('div',{staticClass:"game-icon__ranking"},[_c('div',{staticClass:"game-icon__bg",style:({
                  backgroundImage: ("url(" + (require("@/assets/images/bg_bangdan.png")) + ")")
                })},[_c('div',{staticClass:"flex game-icon__info"},[_c('img',{staticStyle:{"width":"9px","height":"9px"},attrs:{"src":require("@/assets/images/icon_bangdan.png"),"alt":"BNAGDAN"}}),_c('p',{staticClass:"game-icon__info-desc"},[_c('span',[_vm._v("预约榜第"+_vm._s(_vm.ranking.no)+"名")])]),_c('img',{staticStyle:{"width":"9px","height":"9px"},attrs:{"src":require("@/assets/images/bangdan_icon_more.png"),"alt":"BNAGDAN_MORE"}})])])]):_vm._e()],1),_c('div',{staticClass:"info"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.webDetail.name))]),(_vm.newDetail.new_tag_style)?_c('div',{staticClass:"flex tags"},[_vm._l((_vm.newDetail.new_tag_style.slice(0, 5)),function(item,index){return _c('div',{key:index,staticClass:"tag"},[_vm._v(" "+_vm._s(item.name)+" ")])}),(_vm.newDetail.new_tag_style.length > 5)?_c('div',{staticClass:"more-tag-btn",on:{"click":_vm.handleMoreTags}},[_c('img',{attrs:{"width":"100%","height":"100%","src":require("@/assets/images/icon_tag_more.png"),"alt":"more"}})]):_vm._e()],2):_vm._e()])])]),(_vm.newDetail.event)?_c('div',{staticClass:"up-service",style:({
          backgroundColor: _vm.newDetail.event.high_light
            ? "rgba(36, 149, 255, 0.1)"
            : "rgba(244, 245, 246, 0.6)",
          color: _vm.newDetail.event.high_light ? "#2496ff" : "#999"
        }),on:{"click":function($event){return _vm.handleDownloadGame(_vm.downloadDialogType.GAME_DETAIL)}}},[_c('img',{attrs:{"src":require(("@/assets/images/icon_rili" + (_vm.newDetail.event.high_light ? '_blue' : '') + ".png")),"width":"16px","alt":"DATE"}}),_c('p',{staticClass:"up-service__desc"},[_vm._v(" "+_vm._s(_vm.TimestampFormat(_vm.newDetail.event.time))+"："+_vm._s(_vm.newDetail.event.content)+" ")]),_c('img',{attrs:{"src":require(("@/assets/images/icon_right_arrow" + (_vm.newDetail.event.high_light ? '_blue' : '') + ".png")),"width":"9px","alt":"ARROW"}})]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }