import { render, staticRenderFns } from "./DetailHeader.vue?vue&type=template&id=a380402c&scoped=true"
import script from "./DetailHeader.vue?vue&type=script&lang=ts"
export * from "./DetailHeader.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./style.scss?vue&type=style&index=0&id=a380402c&prod&lang=scss&scoped=true&external")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a380402c",
  "56751480"
  
)

export default component.exports